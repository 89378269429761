.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 2px solid #DDD;
    border-radius: 4px;
    background-color: white;
}

.progressRow {
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(to bottom, white, #f3f3f3);
    border-bottom: 3px solid #1372A8;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    box-sizing: border-box;
}

.progressBlock {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.stepNumber {
    font-size: x-large;
    font-weight: bolder;
    padding: 5px;
}

.stepText {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.stepTitle {
    margin: 0;
    font-weight: bold;
}

.stepTitleActive {
    color: #1372A8;
}

.stepSubtitle {
    margin: 0;
    font-size: small;
}

.phoneProgress {
    display: none;
}

@media (max-width: 800px) {
    .progressRow {
        display: none;
    }

    .phoneProgress {
        display: flex;
    }

    .phoneProgress h2 {
        margin-bottom: 0;
    }
}