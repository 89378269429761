.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mobileHeader{
    display:none;
}
.playButtonBox {
    background-color: transparent;
    border: none;
}
.topHeaders{
    width:100%;
}
.headerMid {
    font-size: xx-large;
    color: white;
}

.subHeaderMid {
    font-size: x-large;
    color: white;
}

.backButton {
    position: absolute;
    top: 5px;
    left: 5px;
    border: none;
    font-size: x-large;
    background-color: #0A0A0A;
    color: #ffc627;
    z-index: 200;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
}

.backButton:hover {
    background-color: #ffc627;
    color: #0A0A0A;
    cursor: pointer;
}

.backButtonIcon {
    border: 1px solid #ffc627;
}

.backButtonText {
    margin-left: 5px;
}

.backButtonPlaceholder {
    height: 30px;
    width: 20px;
    display: none;
}

.thanks {
    color: #ffc627;
    font-size: xx-large;
}

.buttonYes {
    background-color: #0092D5;
    display: inline-block;
    color: white;
    text-shadow: 1px 1px #0a0810;
    font-size: xx-large;
    font-weight: bolder;
    padding: 10px 25px;
    margin: 30px 0 10px 0;
    border-radius: 3px;
    border: 1px solid rgba(80, 80, 80, 0.97);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
}

.contactBox {
    display: flex;
    justify-content: center;
    width: 50%;
    align-self: center;
    margin-top: -1%;
}

.subRoot {
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: white;
    justify-content: center;
}

.title {
    font-size: 6vh;
    margin-top: -1%;
}

.subText {
    font-size: xx-large;
    width: 50%;
    align-self: center;
}

.bgVid {
    background-color: black !important;
}

.mobilePlay {
    display: none;
}


.videoBackground {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    width: 66%;
}

.playVid {
    color: #efbb2e;
    font-size: 30px;
    font-family: Cinzel;
    font-weight: 900;
    letter-spacing: 25px;
}

.goldDivider {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #efbb2e;
    width: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    display: inline-block;
    margin: 4% 0 3% 0;


}

.bottomColumns {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.playVideoRow {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin: 25px 0;
}

.playButton {
    margin: 0 15px 0 0;
    z-index: 10;
}

.popupRoot {
    display: flex;
    z-index: 99999;
    top: 0;
    justify-content: center;
    width: 100%;
    position: absolute;

    padding-top:10%;
}
.hubContainer{
    width: 60%;
    display: flex;
}

.popupContainer {
    display: flex;
    width: 60%;
    position:sticky;
    background: white;
}


.vidOverlay {
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    position: center;
     z-index: 1;

}

.btnGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;

}

.ctaButton {
    margin: 3% 2%;
    color: #ffc627;
    background-color: transparent;
    border: 2px #ffc627 solid;
    border-radius: 2px;
    padding: 2% 3%;
}

.signUp {
    padding: 2% 2%;
    font-size: 30px;

}

.contactUs {
    padding: 2% 1%;
    font-size: 25px;
}

.blackOverlay {
    background-color: black;
}

.mainBg {
    width: 100%;
    z-index: 10;


    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.learnMore {
    color: white;
    font-size: x-large;
    font-weight: 700;
    margin-bottom: 3%;

}

.buttonCtaRow {
    display: flex;
    width: 100%;
    justify-content: center;
}

.yellow {
    color: #ffc627;
}

.videoWrapper {
    border: solid 4px white;
    border-radius: 4px;
    margin-bottom: 3%;
}

.divider {
    background-color: transparent;
    z-index: 10;
    position: relative;
}

.buttonRow {
    margin-bottom: 3%;
}

.subTitleText {
    color: white;
    font-size: 6vh;
    margin-bottom: 20px;
    margin-top: 10%;
    font-weight: 700;
    width: 95%;
    line-height: 1;

    font-family: Cinzel;
}

.videoAlign {
    display: flex;
}

.titleText {
    color: white;
    font-size: 5.5vh;
    margin-top: 10%;
    line-height: 1.2em;
    margin-bottom: 10px;

    font-family: Montserrat, Sans-serif;
    width: 100%;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;

    font-weight: 300;
}

.specialOffer {
    margin: 16px auto 10px auto;
    padding: 5px 65px;
    font-size: 32px;
    background-color: rgb(243, 187, 43);
    color: rgb(45, 45, 45);
    font-weight: 700;
}

.specialOffer span {
    text-decoration: underline;
}

.offerWhite {
    font-size: 28px;
    color: white;
    font-weight: 700;
    margin: auto;
    padding-bottom: 14px;
}

.greenPulse {
    padding: 5px 20px;
    width: 100%;
    border: 1px solid #ffc627;
    color: #0A0A0A;
    margin-bottom: 15px;
    font-size: 46px;
    background-color: #ffc627;
    box-sizing: border-box;
    font-weight: bolder;
    border-bottom: 5px solid #c18b26;
    border-radius: 8px;
}

.greenPulse:hover {
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.buttonSubtext {
    font-weight: normal;
    font-weight: 700;
    width: 100%;
}

.floatButtonBox {
    height: 100px;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #0A0A0A;
}

.floatButton {
    background-color: #00f129;
    border-bottom: 5px solid #0aa024;
    width: 100%;
    height: 70%;
    border-radius: 8px;
    font-size: x-large;
    font-weight: bolder;
    -webkit-animation: floatPulse 1s ease-in-out infinite alternate;
    -moz-animation: floatPulse 1s ease-in-out infinite alternate;
    animation: floatPulse 1s ease-in-out infinite alternate;
    user-select: none;
}

.floatPlaceholder {
    height: 100px;
    display: none;
}

@-webkit-keyframes floatPulse {
    from {
        transform: scale(0.9, 0.9);
    }
    to {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes greenPulse {
    from {
        -webkit-box-shadow: 0 0 8px #ffc627;
    }
    50% {
        -webkit-box-shadow: 0 0 1px #fff114;
    }
    to {
        -webkit-box-shadow: 0 0 7px #ffc627;
    }
}

.ctaBorder {
    margin-top: 200px;
}

@media (orientation: portrait) {
    .mainBg {
        background-size: auto 100%;
    }
}

@media (max-width: 1100px) {
    .playVid {
        font-size: x-large;
    }


    .videoBackground {
        width: 95%;
    }

    .titleText {
        font-size: xx-large;
    }

    .greenPulse {
        font-size: xx-large;
        width: 90%;
    }

    .offerWhite {
        font-size: x-large;
    }
}

@media (max-width: 800px) {
    .mobileHeader{
        display: flex;
        z-index: 999999;
        width: 100%;
    }
    .topHeaders{
        display: none;
    }
    .subTitleText {
        font-size: xx-large;
        width: 100%;
    }

    .contactBox {
        width: 100%;
    }

    .mobilePlay {
        display: flex;
        padding-right: 8%;
        margin: 1% 0;
        justify-content: center;
        z-index: 10;

    }

    .playButton {
        display: none;
    }

    .playVideoRow {
        flex-direction: column;
    }

    .learnMore {
        font-size: larger;
    }

    .buttonSubtext {
        font-size: medium;
        color: grey;
    }

    .floatButtonBox {
        display: flex;
        z-index: 10;

    }

    .floatPlaceholder {
        display: block;
    }

    .videoBackground {
        width: 100%;
    }

    .videoWrapper {
        border: none;
    }

    .mobileDivider {
        display: none;
    }

    .titleText {

        font-size: 4.5vh;
        width: 95%;
        line-height: 1em;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .learnMore {
        font-weight: bolder;
        margin-bottom: 10px;
    }

    .greenPulse {
        font-size: x-large;
        width: 90%;
    }

    .subText {
        width: 100%;
        margin-top: -5%;
    }

    .title {
        margin-top: -3%;
    }

    .offerWhite {
        font-size: large;
        width: 95%;
        margin-top: 10px;
    }
}