.mainContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.root {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    margin-top: 30%;
    flex-direction: row;
}

.divider {
    margin-top: -5%
}

.subRoot {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    flex-direction: row;
    margin-top: 5%;
}

.rowContainer {
    display: flex;
    flex-direction: row;
    width: 100%;


}

.mobileRoot {
    display: none;
}

.columnContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    min-height: 1px;
    justify-content: center;

}

.headingText {
    margin-left: 3%;
    margin-right: 3%;
    font-size: xx-large;
    font-family: Montserrat, Sans-serif;
    font-weight: 800;
    line-height: 1.2em;
    color: black;
    width: 70%;
    text-align: left;
    margin-bottom: 5%;

}

.description {
    margin-right: 3%;
    margin-left: 3%;
    text-align: left;
    width: 70%;
    font-family: "Source Sans Pro", sans-serif;
}

.images {
    width: 50%;
    height: 100%;
    padding: 0 4%;
}

.columnWrap {
    padding: 0 0 3% 10%;
    width: 90%;
    position: relative;

}

@media (max-width: 900px) {
    .root {
        margin-top: 0;
    }

    .images {
        width: 90%;
    }

    .rowContainer {
        display: none;
    }

    .description {
        text-align: center;
        width: 100%;
        margin-top: 5%;
        font-size: large;
        font-weight: 300;
    }

    .mainContainer {
        display: flex;
        flex-direction: column;
    }

    .mobileRoot {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30%;
        justify-content: center;


    }

    .mobileSubRoot {
        display: flex;
        flex-direction: column;
        width: 100%;
        color: black;
        margin-top: 30%;

        margin-bottom: 10%;


    }

    .divider {
        display: none;
    }

    .headingText {
        margin: 10% 0 3% 0;
        width: 100%;
        text-align: center;
    }

    .mobileColumns {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .desktopDivider {
        display: none;
    }

}