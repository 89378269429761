.blackOverlay {
    background-color: black !important;
}

.section {
}

.bgVid {
    background-color: black !important;
}
.vidProp{
    top: -75px !important;
}

.container {

    background-color: rgba(0, 0, 0, 0.40);
    color: white;
    width: 100%;
    height: 50%;
    position: absolute;
}

.subContainer {
    margin-top: 10%;
}

.headerText {
    font-family: "Montserrat", Sans-serif;
    font-weight: 800;
    font-size: 6vh;
    text-shadow: 3px 0px 10px rgba(0, 0, 0, 0.53);
}

.jb {
    font-family: "Cinzel", Sans-serif;
    font-weight: 700;
    text-shadow: 3px 0px 10px rgba(0, 0, 0, 0.53);
    color: #ffc627;
    font-size: 5.5vh;
}

@media (max-width: 900px) {

    .headerText{
        font-size: 5vh;
    }
    .subContainer{
        margin-top: 15%;
    }
    .jb{
        font-size: 4.5vh;
    }
        .container{
            height:30%;
        }
}