.root {
    background-image: url("../../assets/images/jordanStageBG.png");
    background-size: cover;
    background-position: center;
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 32px);
}

.thanks {
    color: #ffc627;
    font-size: xx-large;
}

.backButton:hover {
    background-color: black;
    color: #ffc627;
    cursor: pointer;
}

.textContainer {
    display: flex;
    flex-direction: column;
    width: 70%;

}

.title {
    color: #ffc627;
    font-family: Merriweather, Sans-serif;
    font-size: 6vh;
    margin-bottom: 2%;
    padding-top: 3%;

}

.subTitle {
    font-size: 5vh;
    font-family: Merriweather, Sans-serif;
    color: #ffc627;
    margin-top: 0;
    padding-bottom: 5%;
}

.subRoot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.backButtonIcon {
    border: 1px solid #ffc627;
}

.backButton {
    position: absolute;
    top: 5px;
    left: 5px;
    border: none;
    font-size: large;
    background-color: #ffc627;
    color: black;
    z-index: 200;
    border-radius: 4px;
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
}

.buttonYes {
    background-color: #0092D5;
    display: inline-block;
    color: white;
    text-shadow: 1px 1px #0a0810;
    font-size: xx-large;
    font-weight: bolder;
    padding: 10px 25px;
    margin: 30px 0 10px 0;
    border-radius: 3px;
    border: 1px solid rgba(80, 80, 80, 0.97);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
}

@media (max-width: 900px) {
    .title {
        margin-top: 10%;
        font-size: x-large;
        padding-top: 10%;
    }

    .textContainer{
        width: 90%;
    }

    .subTitle {
        font-size: medium;
        color: white;
    }
}