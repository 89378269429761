.root {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 78px;
    padding: 5px 25px;
    z-index: 10;
    /* background-color: rgba(0, 0, 0, 0.75); */
    position: relative;
}

.mobileSubRoot {
    display: none;
}

.title {
    font-size: xx-large;
    font-family: Cinzel;
    font-weight: 700;
}

.subRoot {
    display: flex;
    margin-top: 6%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 10;
}

.supportText {
    font-size: 20px;
}

@media (max-width: 800px) {
    .root {
        height: auto;

    }

    .mobileSubRoot {
        display: flex;
        margin-top: -5%;

        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        z-index: 10;
    }

    .subRoot {
        display: none;
    }

    .logoBox {
        margin: 0;
    }

    .supportText {
        margin: 0;
        font-size: small;
    }
}