.App {
  text-align: center;

}
.popup-content{
  width: 50% !important;

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

path[d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"] {
  display: none;
}
path[d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"] {
  display: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media(max-width: 660px){
  .popup-content{
    right: 0;
    left: 0 !important;
    width: 90% !important;

  }
}