.root {
    display: flex;
    flex-direction: column;
    background-color: #0b1e27;
    align-items: center;
    justify-content: center;
}
.divider{
    display: flex;
    flex-direction: row;
}

.subRoot {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
    justify-content: center;
}

.title {
    color: white;
    font-size: 6vh;
    margin-top: 60px;
    line-height: 1.2em;
    margin-bottom: 10px;
    font-family: Montserrat, Sans-serif;
    width: 100%;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;

    font-weight: 300;
}

.subtitle {
    color: rgb(62, 137, 72);
    font-size: 32px;
    margin-bottom: 5px;
}

.video {
    margin: 20px;
}

.mainInfo {
    color: white;
    margin: 1%;
    font-size: x-large;
    width: 95%;
    font-family: Merriweather, Sans-serif;
}

.importantTitle {
    color: rgb(228, 59, 44);
    font-size: xx-large;
    margin-bottom: 5px;
}

.importantContent {
    color: #ffc627;
    font-size: x-large;
    margin-top: 1%;
    width: 80%;
}

.importantContent a {
    color: white;
    text-decoration: none;
}

.importantContent a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.steps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 30px 0;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.step {
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #0A0A0A;
    border-radius: 8px;
    border: 1px solid #ffc627;
    margin: 5px;
    width: 30%;
    min-width: 250px;
    box-sizing: border-box;
    padding: 10px;
}

.stepTitle {
    font-size: x-large;
    font-weight: bolder;
}

.stepContent {
    font-size: large;
    margin: 2px 0;
}

.productList {
    border-radius: 4px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 0;
}

.product {
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ffc627;
    color: white;
    list-style: none;
    width: 100%;
    margin: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.buyable {
    justify-content: space-between;
    border-radius: 4px;
}

.buyable:hover {
    background-color: #ffc627;
    color: black;
}

.productTitle {
    font-size: x-large;
    width: 80%;
    color: #ffc627;
    margin-top: 10px;
    margin-bottom: 5px;
}

.checkBox {
    width: 32px;
    height: 32px;
    border: 2px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
}

.check {
    width: 100%;
    height: 100%;
}

.productName {
    font-size: x-large;
    font-weight: bold;
}

.buyable:hover .checkBox {
    border-color: black;
}

.creditWrapper {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
}

.buyButton {
    font-size: x-large;
    padding: 10px;
    background-color: green;
    color: white;
    border: 1px solid darkgreen;
    border-bottom: 5px solid darkgreen;
    border-radius: 8px;
}

.buyButton:hover {
    background-color: #009c00;
}

.buyButton:disabled {
    background-color: #9c9c9c;
    border-bottom-color: #6d6d6d;
}

@media (max-width: 1000px) {
    .subRoot {
        width: 70%;
    }
}

@media (max-width: 800px) {

    .root {
        padding: 0;
    }


    .subRoot {
        width: 100%;
    }

    .productList {
        width: 95%;
        padding: 0;
    }

    .product {
        margin-left: 0;
        margin-right: 0;
        box-sizing: border-box;
        font-size: small;
    }

    .checkBox {
        width: 24px;
        height: 24px;
    }

    .productName {
        font-size: medium;
        width: 45%;
    }

    .steps {
        flex-direction: column;
    }

    .step {
        width: 95%;
    }

    .title {
        font-size: xx-large;
        margin: 15px 0 0 0;
        width: 95%;
    }

    .subtitle {
        font-size: x-large;
    }

    .importantTitle {
        font-size: x-large;
    }

    .importantContent {
        font-size: large;
    }

    .mainInfo {
        width: 95%;
    }

}