.mainContainer {
    margin-top: -5%;
    margin-bottom: 5%;
    width: 90%;
    background-color: white;
    display: flex;
    flex-direction: row;
    text-align: left;
}

.iconSectionHeader {
    font-size: 3.1vw;
}

.iconSectionHeaderRow {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 4% 0 2% 0;
}

.iconRow {
    display: flex;
    flex-direction: row;
    margin: 4% 2%;
}

.icon {
    margin: 6% 4% 0 0;
}

.root {
    margin-top: 5%;
}

.rowList {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.text {
    display: flex;
    flex-direction: column;
    width: 35%;
    line-height: 1.5em;
}

.header {
    line-height: normal;
}

.ctaRow {
    margin: 5% 0 1% 0;
    display: flex;
    justify-content: center;
}

@media (max-width: 900px) {

    .mainContainer {
        width: 100%;
        justify-content: center;
    }

    .root {
        display: flex;
        flex-direction: column;
        width: 90%;
        justify-content: center;
    }

    .text {
        width: 100%;
    }

    .rowList {
        flex-direction: column;
    }
}