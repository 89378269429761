.root {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color:#ffffff;
    margin-top: 10px;
    background-color: transparent;
}
.subRoot{
    width: 65%;
    display: flex;
    flex-direction: row;

}
.quote{
    font-size: 1.9vh;
    font-weight: 600;
    font-style: italic;
    font-family: Source Sans Pro, Sans-serif;
    padding: 0 5%;
}
.quoteImage{
    margin-right: 10px;

}
.memberName{
    margin-top: 2%;
}

@media (max-width: 800px) {
    .quoteImage{
        width: 30px;
    }
    .subRoot{
        width: 95%;
    }
    .quote{
        font-size: 1.5vh;
        padding: 0 1%;
        font-weight: 700;
    }
}