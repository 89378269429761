.root {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: white;
    justify-content: center;
    margin: 0;
    padding: 0;


}

.memberVid {
    margin: 0 10px;
}

.mobileVideo {
    display: none;
}

.video {
    max-width: 50%;
}

.subRoot {
    display: flex;
    width: 100%;
    flex-direction: column;

}

.leo {
    width: 100%;
}

.image {
    max-width: 90%;
    border-radius: 15px;
}

.title {
    color: black;
    margin-top: 6%;
    font-family: Cinzel, Sans-serif;
    font-size: 6vh;
    font-weight: 700;
    line-height: .9em;
}

.subTitle {
    color: #ffc627;
    font-family: Cinzel, Sans-serif;
    font-size: 5vh;
    font-weight: 700;
    margin-bottom: 4%;
}

.members {
    display: flex;
    flex-direction: row;
    margin-top: 3%;
    width: 100%;
    justify-content: center;
}

.membersImage {
    border-radius: 8px;
    /*width: 70%;
    height:auto;*/
    justify-content: center;


}

.membersText {
    display: flex;
    flex-direction: row;
    margin-top: 1%;

    width: 100%;
    justify-content: center;

}

.names {
    font-size: large;
    font-weight: 800;
    margin-top: 5%;
    font-family: Montserrat, Sans-serif;
}

.positions {
    font-size: small;
    font-weight: 400;
    margin-top: 2%;
    font-family: "Source Sans Pro", Sans-serif;
}

.imageGroup {
    display: flex;
    flex-direction: column;


}

.buttons {
    margin-top: 3%;
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
}


@media (max-width: 800px) {

    .subRoot {
        width: 90%;
    }
    .image{
        max-width: 100%;
    }

    .membersText {
        flex-direction: column;
    }

    .video {
        display: none;
    }

    .mobileVideo {
        display: block;
    }

    .names {
        margin-top: 0;
    }
}