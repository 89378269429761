.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url("../../assets/images/bg.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
    color: white;
    min-height: calc(100vh);
    padding-bottom: 10px;
    box-sizing: border-box;
}

.infoBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    color: black;
    border-radius: 8px;
    max-width: 60%;
    box-sizing: border-box;
}

.brainTreeWrapper {
    display: none;
}

.title {
    font-size: 46px;
    margin: 0;
    width: 50%;
    z-index: 51;
}

.title b {
    color: #ffc627;
}

.subtitle {
    width: 50%;
    z-index: 51;
    margin: 10px 0;
}

.videoTitle {
    font-weight: normal;
    font-size: xx-large;
    margin: 0;
}

.videoSubtitle {
    font-size: 46px;
    color: #ffc627;
    font-weight: bolder;
    margin: 0;
}

.left {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.left p {
    margin: 20px 0;
    font-size: large;
    width: 70%;
}

.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    width: 30vh;
    z-index: 51;
}

.image {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;

}

.centeredImageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    box-sizing: border-box;
}

.imageBoxJordan {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    max-height: 100%;
    z-index: 50;
}

.imageBoxJordanMobile {
    display: none;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-height: 100%;
    z-index: 50;
}

.imageJordan {
    max-height: 100%;
    max-width: 100%;

    height: auto;
    width: auto;
}

.videoBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.video {
    margin: 5px;
}

.videoWrapper {
    margin: 20px 0;
}

.buttonRow {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.footerPlaceholder {
    display: none;
}

.buttonWrapper {

}

.noButton {
    background-color: red;
    border: 1px solid orangered;
    border-bottom: 5px solid darkred;
    color: white;
    font-size: x-large;
    border-radius: 8px;
    padding: 10px;
    margin: 10px;
}

.noButton:hover {
    background-color: orangered;
    cursor: pointer;
}

.yesButton {
    background-color: green;
    border: 1px solid mediumseagreen;
    border-bottom: 5px solid darkgreen;
    color: white;
    font-size: 26px;
    line-height: 1em;
    margin: 10px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px 30px;
}

.yesButton:hover {
    background-color: forestgreen;
    cursor: pointer;
}

.yesButton:disabled {
    background-color: gray;
    border-color: gray;
}

.listBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.listTitle {
    font-size: x-large;
    font-weight: bolder;
    color: #9a9a9a;
    margin: 4px 0;
}

.list {
    padding: 5px;
    width: 80%;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    font-size: large;
    font-weight: bolder;
    margin-bottom: 5px;
    width: 100%;
}

.listItem p {
    margin: 0;
}

.check {
    min-width: 45px;
    min-height: 45px;
    margin-right: 20px;
    color: #ffc627;
}

.priceBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid green;
    padding: 0 10px;
}

.oldPrice {
    font-size: xx-large;
    color: red;
}

.newPrice {
    margin: 0;
    font-size: 46px;
    color: green;
    font-weight: bolder;
}

.arrow {
    min-width: 45px;
    min-height: 45px;
    color: green;
    margin: 0 20px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    width: 100%;
    position: sticky;
    top: 0;
    font-size: large;
    padding: 5px 10px;
    box-sizing: border-box;
    z-index: 100;
}

.bannerBox {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.banner {
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

@media (max-width: 1800px) {
    .infoBlock {
        max-width: 95%;
    }
}

@media (max-width: 1100px) {
    .root {
        height: auto;
        padding-bottom: 20px;
        min-height: calc(100vh - 88px);
    }

    .imageBoxJordanMobile {
        display: flex;
    }

    .imageBoxJordan {
        display: none;
    }

    .infoBlock {
        flex-direction: column-reverse;
    }

    .left {
        flex-direction: column-reverse;
    }

    .buttonRow {
        flex-direction: column-reverse;
        width: 95%;
        position: fixed;
        bottom: 0;
        background-color: black;
        z-index: 100;
    }

    .footerPlaceholder {
        height: 160px;
        display: block;
    }

    .imageBox {
        margin-bottom: 20px;
        height: 500px;
        width: 100%
    }

    .image {
        height: auto;
        width: auto;
    }

    .videoSubtitle {
        font-size: xx-large;
    }

    .videoTitle {
        font-size: x-large;
    }
}

@media (max-width: 800px) {
    .root {
        height: auto;
        padding-bottom: 20px;
        min-height: calc(100vh - 88px);
    }

    .centeredImageBox {
        width: 95%;
    }

    .title {
        width: 95%;
        font-size: xx-large;
    }

    .subtitle {
        width: 90%;
        font-size: x-large;
    }

    .infoBlock {
        width: 95%;
    }

    .buttonRow {
        flex-direction: row-reverse;
        width: 100%;
    }

    .yesButton {
        -webkit-animation: floatPulse 1s ease-in-out infinite alternate;
        -moz-animation: floatPulse 1s ease-in-out infinite alternate;
        animation: floatPulse 1s ease-in-out infinite alternate;
    }

    .buttonWrapper {
        width: 50%;
    }

    .imageBox {
        margin-bottom: 20px;
        height: 300px;
        width: 100%
    }

    .image {
        height: auto;
        width: auto;
    }

    .videoSubtitle {
        font-size: 26px;
    }

    .videoTitle {
        font-size: 20px;
    }

    .listBox {
        max-width: 100%;
    }
}

@-webkit-keyframes floatPulse {
    from {
        transform: scale(0.95, 0.95);
    }
    to {
        transform: scale(1, 1);
    }
}