.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("../../assets/images/bg.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
}

.bannerBox {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
}

.banner {
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    display: none;
}

.desktopBanner {
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    display: block;
}

.notice {
    width: 100%;
    padding: 20px 20%;
    background-color: green;
    color: white;
    margin: 0;
    box-sizing: border-box;
    font-size: x-large;
}

.notice2 {
    width: 100%;
    padding: 20px 20%;
    background-color: black;
    color: white;
    margin: 0;
    box-sizing: border-box;
    font-size: x-large;
}

.title {
    color: black;
    font-size: 42px;
}

.checksBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    background-color: white;
    max-width: 60%;
    border-radius: 8px;
    margin: 20px;
    box-sizing: border-box;
}

.checksSubBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
}

.checksTitle {
    color: black;
    font-size: xx-large;
    margin: 0;
}

.checksWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.checks {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.check {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
}

.check p {
    font-weight: bolder;
    text-align: left;
    margin: 0;
    font-size: large;
}

.checkIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    margin: 10px;
}

.button {
    background-color: green;
    color: white;
    padding: 10px;
    width: 32%;
    font-size: x-large;
    border-radius: 8px;
    border: 1px solid mediumseagreen;
    border-bottom: 5px solid darkgreen;
    max-width: 500px;
    margin: 10px;
}

.button:hover {
    background-color: #009600;
}

.button:disabled {
    background-color: gray;
    border-color: gray;
}

.button p {
    margin: 5px 0;
    font-weight: lighter;
}

.boxIcon {
    margin-right: 10px;
}

.offerWarning {
    color: #ffc627;
    margin: 5px 0;
}

.instantDownload {
    color: #ffc627;
    margin: 5px 0;
}

.imgBox {
    width: 30%;
    margin: 40px;
}

.img {
    max-width: 100%;
    max-height: 100%;
}

.offerBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    box-sizing: border-box;
    background-color: rgb(45, 45, 45);
    border: 5px dashed rgb(66, 185, 159);
    padding: 20px;
}

.offerTitle {
    color: white;
    font-size: xx-large;
}

.priceWarning {
    color: rgb(228, 59, 44);
    font-weight: bolder;
    font-size: xx-large;
    margin: 5px;
}

.priceOffer {
    color: rgb(66, 185, 159);
    font-weight: bolder;
    font-size: xx-large;
    margin: 5px;
}

.subWarning {
    color: white;
    margin-bottom: 5px;
}

.offerDownloadText {
    color: #ffc627;
    margin-top: 5px;
}

.moneyBackBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}

.guaranteeBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.guaranteeImgBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
}

.guaranteeImg {
    max-height: 100%;
    max-width: 100%;
}

.guaranteeInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.guaranteeTitle {
    font-size: x-large;
    font-weight: bolder;
}

.guaranteeBody {
    font-size: small;
    width: 80%;
}

.encryptedMessage {
    font-size: small;
    width: 50%;
}

.skipButton {
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    width: 30%;
    color: white;
    background-color: red;
    border: 1px solid orangered;
    border-bottom: 5px solid darkred;
    font-size: x-large;
    border-radius: 8px;
}

.skipButton:hover {
    background-color: #ff4a2f;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    padding-bottom: 20px;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.links a {
    color: #ffc627;
    border: 1px solid #ffc627;
    margin: 5px;
    padding: 5px;
}

.links a:hover {
    background-color: #ffc627;
    color: black;
}

.select {
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    margin-top: 5px;
    font-size: large;
}
.floating {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.buttonPlaceholder {
    display: none;
    height: 180px;
    width: 100%;
    background-color: black;
}

.slpButtonWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.creditBlock {
    display: none;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    border-radius: 4px;
    margin: 10px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    width: 100%;
    position: sticky;
    top: 0;
    font-size: large;
    padding: 5px 10px;
    box-sizing: border-box;
    z-index: 100;
}

@media (max-width: 800px) {
    .desktop {
        display: none;
    }

    .creditBlock {
        width: 95%;
    }

    .banner {
        display: block;
    }

    .desktopBanner {
        display: none;
    }

    .floating {
        display: flex;
    }

    .buttonPlaceholder {
        display: block;
    }

    .slpButtonWrapper {
        display: none;
    }

    .checksBlock {
        max-width: 95%;
    }

    .checksSubBlock {
        width: 100%;
    }

    .checksWrapper {
        flex-direction: column;
    }

    .checks {
        width: 95%;
        margin: 0;
    }

    .check {
        width: 100%;
    }

    .check p {
        font-size: medium;
    }

    .button {
        width: 50%;
        background-color: green;
        color: white;
        display: block;
        border-bottom: 5px solid darkgreen;
        border-radius: 8px;
        font-size: x-large;
    }

    .imgBox {
        width: 90%;
    }

    .skipButton {
        width: 50%;
        background-color: red;
        border-radius: 8px;
        border-bottom: 5px solid darkred;
        color: white;
        font-size: x-large;
    }

    .title {
        font-size: x-large;
    }

    .checksTitle {
        font-size: x-large;
    }

    .notice, .notice2 {
        padding: 5px 5%;
        margin: 0;
        box-sizing: border-box;
        font-size: large;
    }

    .offerTitle, .priceWarning, .priceOffer {
        margin: 10px 0;
    }

    .offerBlock {
        margin-top: 10px;
    }
}