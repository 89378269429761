.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 20px;
}

.spinnerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.input {
    width: 100%;
    font-size: large;
    border-radius: 5px;
    background-color: #F1F1F1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,0,0,0.05);
    padding: 12px 18px;
    margin-top: 15px;
}
.input select, .input input {
   background-color:  #F1F1F1;
}
.inputError {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid red;
}

.submit {
    width: 100%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: rgb(14, 141, 199);
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
    margin-top: 15px;
}

.submit:hover {
    cursor: pointer;
}

.phone {
    display: none;
}

.subtext {
    font-size: small;
    color: #9c9c9c;
    margin: 5px;
}

.error {
    margin: 0;
    background-color: red;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid red;
    width: 100%;
}

.formError {
    margin: 0;
    background-color: red;
    border-radius: 4px;
    border: 1px solid red;
    padding: 10px;
    width: 100%;
}

@media (max-width: 800px) {
    .phone {
        display: block;
    }

    .desktop {
        display: none;
    }
}