.mainContainer {

    margin-top: -5%;
}

.root {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: rgb(245, 245, 245);
}

.divider {
    z-index: 10;
    position: relative;
}

.headerContainer {
    margin: 4% 0;
}
.increaseHeader{
    font-size: 3.1vw;
}

.mobileRoot {
    display: none;
}

.mobileDivider {
    display: none;
}

.containerE {
    position: relative;
    width: 50%;
    max-width: 300px;
}

.imageE {
    display: block;
    width: 100%;
    height: auto;
}

.overlayE {
    position: absolute;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    width: 100%;
    opacity: 0;
    color: white;
    font-size: 20px;
    padding: 20px;
    opacity: 1;
}

.ctaRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    margin-bottom: 2%;
}

.sectionDivider {
    background-image: url(../../../assets/images/sectionDivider.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-bottom: 0%;
    padding: 9% 0% 6% 0%;
}

.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.buttonRow {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
}

.left {
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.right {
    height: auto;
    padding: 10px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

}

.iconSection {
    margin-top: 5%;

}

.imageContainer {
    display: flex;
    width: 70%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.imageBox {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid black;
    overflow: hidden;
    margin: 20px;
}

.image {
    max-width: 100%;
    max-height: 100%;
}

.iconContainer {
    width: 80%;
    margin: 0 0 4% 0;
    color: black;
    font-size: large;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.headingRow {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-bottom: 3%;
    min-height: 216px;

}

.headingText {
    margin-top: 2%;
    font-size: 34px;
    font-weight: 800;
    text-align: left;
    line-height: 1.2em;
    font-family: Montserrat, sans-serif;
}

.listText {
    margin: 0 0;
    width: 40%;
    font-size: large;
    font-family: "Source Sans Pro", sans-serif;
    text-align: left;
    line-height: 1.45;
}
.videoRightBox{
    display: flex;
}

.lastList {
    width: 40%;
    font-size: large;
    font-family: "Source Sans Pro", sans-serif;
    text-align: left;
    margin-right: 5%;
    line-height: 1.45;
}

.checks {
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    padding: 5px 14px;
    margin: 5px 0;
    text-align: left;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checks p {
    margin: 0;
    text-align: center;
}

.check {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    margin-right: 10px;
    background-color: black;
    color: #ffc627;
    border-radius: 50%;
    font-size: larger;
    font-weight: bold;
}


.mobileBlock {
    display: none;
}

@media (max-width: 800px) {
    .mainContainer {
        flex-direction: column;
        width: 100%;
        background-color: white;

    }

    .mobileText {
        font-size: large;
        text-align: left;
        padding-left: 25px;
    }

    .root {
        display: none;
    }

    .mobileRoot {
        display: flex;
        width: 100%;
        margin-top: 10%;
        justify-content: center;

    }

    .left {
        padding: 0;
    }

    .right {
        padding: 0 0 20px 0;
        background-color: #dddddd;
        width: 100%;
    }

    .imageContainer {
        flex-direction: row;
        width: 95%;
    }

    .imageBox {
        width: 80%;
        margin: 10px;
        border: none;
        box-shadow: -3px 3px 15px -3px rgba(0, 0, 0, 0.5);
    }

    .ctaRow {
        width: 100%;
        margin: 10px 0 0 0;
    }

    .subText {
        font-size: 24px;
        margin: 3% 0 3% 0;
        width: 95%;
        line-height: 1em;
    }

    .headingText {
        font-size: x-large;
        text-decoration: none;
        text-align: left;

    }

    .iconRow {
        display: flex;
        flex-direction: row;
        margin-top: 10%;

    }

    .newRow {
        display: flex;
        flex-direction: row;

    }

    .checks {
        font-size: large;
        flex-direction: column;
        width: 85%;
        line-height: 1.1em;
        font-weight: normal;
    }

    .check {
        margin: 0;
    }

    .ctaButton {
        font-size: x-large;
        display: none;
    }


    .iconContainer {
        flex-direction: column;
        width: 100%;
    }

    .listText {
        width: 90%;
        font-size: large;
        margin-bottom: 10%;
    }

    .lastList {
        width: 90%;
        font-size: large;
        margin-bottom: 5%;
        text-align: left;
    }

    .mobileBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
    }

    .mobileDivider {
        display: inline;
    }

    .mobileBanner {
        width: 100%;
        color: #ffc627;
        background-color: black;
        padding: 15px;
        font-size: x-large;
        font-weight: bolder;
        margin: 10px;
        box-sizing: border-box;
    }

    .mobileInfo {
        width: 95%;
        line-height: 1em;
        margin: 10px 0;
    }

}