.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.spinnerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.itemButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;

}

.itemButton {
    width: 33%;
    margin: 5px;
    background-color: white;
    font-size: large;
    /*box-sizing: border-box;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0 solid white;
    overflow: hidden;
    min-height: 200px;
    position: relative;
    box-shadow: 5px 5px 10px -5px black;
    padding: 10px;
    outline-color: green;
}

.itemButtonText {
    font-weight: bold;
    margin: 10px;
}

.errorItemButton {
    border-color: red;
}

.itemButton:hover {
    color: blue;
    border: 0px solid blue;
    background-color: #d8d8d8;
}

.activeButton {
    border: 0px solid green;
    background-color: #FFFF99;
}

.fakeButton {
    background-color: black;
    color: white;
    border-radius: 4px;
    padding: 8px 20px;
    font-weight: bold;
    bottom: 10px;
    position: absolute;
}


.bannerBox {
    overflow: hidden;
    width: 100px;
    height: 100px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
}

.banner {
    background-color: green;
    color: white;
    padding: 5px 20px;
    min-width: 120px;
    font-size: small;
    font-weight: bold;
    transform: translate(20px, -20px) rotate(45deg);
    -webkit-transform: translate(20px, -20px) rotate(45deg);
}

.bannerLess {
    background-color: #c4c4c4;;
    color: white;
    padding: 5px 20px;
    min-width: 120px;
    font-size: small;
    font-weight: bold;
    transform: translate(20px, -20px) rotate(45deg);
    -webkit-transform: translate(20px, -20px) rotate(45deg);
}

.mostPopular {
    background-color: #c4c4c4;
    color: white;
    padding: 5px;
    font-size: small;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
}

.check {
    color: green;
    font-size: x-large;
}

.checkHolder {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
}

.creditFormBlock {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(221, 221, 221, 0.18);
    border: 1px solid rgb(221, 221, 221);
}

.rowBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.columnBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 10px;
    width: 50%;
}

.wide {
    min-width: 60%;
}

.input {
    width: 100%;
    font-size: medium;
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    padding: 12px 18px;
    margin-top: 5px;
}

.select {
    width: 100%;
    font-size: medium;
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    margin-top: 5px;
}

.errorInput {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: red;
    box-shadow: none;
}

.formError {
    background-color: red;
    box-sizing: border-box;
    border: 1px solid black;
    padding: 15px;
    border-radius: 1px;
    margin-bottom: 10px;
    width: 100%;
    font-size: large;
    font-weight: bold;
}

.inputError {
    background-color: red;
    width: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

.itemError {
    background-color: red;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 4px;
}

.label {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 0;
}

.fastTrackBlock {
    border: 3px dashed black;
    padding: 5px;
    background-color: rgb(252, 248, 227);
    display: none;
}

.ftCheckBlock {
    background-color: rgb(255, 255, 153);
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ftCheckRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.ftCheckbox {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

.ftCheckbox:hover {
    cursor: pointer;
}

.ftCheckLineBoxLeft {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.ftCheckLineBoxRight {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.ftCheckLine {
    width: 1px;
    height: 1px;
    background: green;
    transition: width 1s ease;

    -webkit-animation: expand 1s ease-in-out infinite alternate;
    -moz-animation: expand 1s ease-in-out infinite alternate;
    animation: expand 1s ease-in-out infinite alternate;
}

.ftCheck {
    color: green;
    font-weight: bolder;
    font-size: larger;
}

.offerTitle {
    font-size: large;
    color: green;
    font-weight: bolder;
    margin: 0;
    line-height: 30px;
}

.offerText {
    font-size: small;
}

.offerText b {
    text-decoration: underline;
    color: rgb(204, 51, 0);
}

.submit {
    width: 100%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: #32CD32;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    margin-top: 15px;
}

.submit:hover {
    cursor: pointer;
}

.safeText {
    font-size: small;
    color: #9c9c9c;
}

.paypalText {
    font-size: medium;
    font-weight: bold;
    color: #9c9c9c;
}

.serverError {
    background-color: red;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 4px;
    font-size: large;
}

.paymentButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}

.paymentButton {
    background-color: rgb(221, 221, 221);
    font-size: large;
    border-radius: 4px;
    padding: 5px 10px;
    box-sizing: border-box;
    width: 50%;
    margin: 5px;
}

.paymentButton:hover {
    color: blue;
    border: 1px solid blue;
    background-color: #f3bb2b;
}

.paypal {
    margin: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image {
    max-width: 100%;
    max-height: 100%;
}

@-webkit-keyframes glow {
    from {
        border: 1px solid black;
    }
    to {
        border: 2px solid green;
    }
}


@-webkit-keyframes expand {
    from {
        width: 1px;
    }
    to {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .itemButtons {
        flex-direction: column;
    }

    .itemButton {
        min-height: 80px;
        flex-direction: row;
        width: 100%;
    }

    .fakeButton {
        display: none;
    }

    .checkHolder {
        position: static;
    }

    .itemButtonText {
        margin: 0 30px;
        transform: translateX(-10px);
    }

    .bannerBox {
        min-width: 80px;
    }

    .mostPopular {
        display: none;
    }
}