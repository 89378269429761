.sectionDivider {
    background-image: url(../../../assets/images/sectionDivider.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 18% 0% 2% 0%;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1%;
}

.title {
    color: white;
    font-size: xx-large;
    margin-top: -15%;
    font-family: Cinzel;
    letter-spacing: 9px;
    font-weight: 700;
}
.quotes{
    font-size: large;
    font-weight: 400;
    font-family: Merriweather, Sans-serif;
}

.subTitle {
    font-size: 35px;
    color: white;
    font-weight: 700;
    font-family: Montserrat;
    width: 80%;
    text-align: center;
}

.yellow {
    color: #ffc627;
    font-size: 2.5vw;
}
.yellowTwo{
    color: #ffc627;
font-size: 2vw
}
@media (max-width: 900px) {

    .subTitle{
        font-size: xx-large;
        width: 90%;
    }
    .title{
        font-size: medium;
        letter-spacing: 6px;
        margin-top: 1%;

    }
    .yellow{
        font-size: large;
    }
    .yellowTwo{
        font-size: medium;
    }
    .quotes{
        font-size: large;
    }
}