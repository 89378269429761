.Logo {
    overflow: hidden;
    padding: 0;
    margin-top: 0;
    box-sizing: border-box;
}

.Logo img {
    height: 100%;
}

@media (max-width: 800px) {
    .Logo {
        height:60px;
        margin-top: 10%;
    }
}