
.buttons {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    table-layout: fixed;
    border-collapse: collapse;
    font-family: Montserrat, Sans-serif;

}
.hubContainer{
    width:100%;
}

.hubspotBox {
    padding: 2% 4%;
}

.popupFormField {
    margin: 3% 0;
    display: flex;
    justify-content: center;
}

.popupFormFieldInput {
    width: 200px;
    height: 33px;
}

.popupFormBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 0;

}

.thankYouBox {
    display: flex;
    min-height: 400px;
    flex-direction: column;
    align-items: center;
}

.thankYouText {
    font-size: x-large;
    margin-top: 40%;

}

.thankYouSubText {
    margin-top: 4%;
    font-size: large;
    text-align: center;
}

.popupButton {
    background: #ffc627;
    font-size: large;
    font-weight: bold;
    padding: 6% 25%;
    border-radius: 5px;
}

.checkBoxRow {
    margin-right: 5%;

}

.agreeRow {
    display: flex;
    width: 76%;

}

.buttons button {
    width: 100%;
    border-radius: 3px;
    margin: 0px 10px;
    padding: 5px 5px;
    font-size: xx-large;
    font-weight: bold;
    background-color: #ffc627;
}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    cursor: default;
}

@media (orientation: portrait) {
    .mainBg {
        background-size: auto 100%;
    }
}

@media (max-width: 1100px) {

    .videoBackground {
        width: 95%;
    }

    .titleText {
        font-size: xx-large;
    }

    .greenPulse {
        font-size: xx-large;
        width: 90%;
    }

    .offerWhite {
        font-size: x-large;
    }
}

@media (max-width: 900px) {
    .hideButton {
        display: none;
    }

    .buttons button {
        width: 80%;
        border-radius: 3px;
        margin: 0px 10px;
        padding: 5px 5px;
        font-size: x-large;
        font-weight: bold;
        background-color: #ffc627;
    }

}